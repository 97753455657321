// extracted by mini-css-extract-plugin
export var heroHeaderLeft = "r_rk d_gv d_cs";
export var heroHeaderCenter = "r_gw d_gw d_cs d_dw";
export var heroHeaderRight = "r_gx d_gx d_cs d_dx";
export var heroParagraphLeft = "r_rl d_gr d_cw";
export var heroParagraphCenter = "r_gs d_gs d_cw d_dw";
export var heroParagraphRight = "r_gt d_gt d_cw d_dx";
export var heroBtnWrapperLeft = "r_rm d_d2 d_d1 d_w d_bz d_bG";
export var heroBtnWrapperCenter = "r_rn d_d3 d_d1 d_w d_bz d_bD";
export var heroBtnWrapperRight = "r_rp d_d4 d_d1 d_w d_bz d_bH";
export var overlayBtnWrapper = "r_rq d_gq d_0 d_7 d_8 d_9 d_bn d_cc";
export var design4 = "r_rr d_gp d_0 d_7 d_8 d_bn";
export var heroExceptionSmall = "r_rs x_rs";
export var heroExceptionNormal = "r_rt x_rt";
export var heroExceptionLarge = "r_rv x_rv";
export var Title1Small = "r_rw x_rw x_rY x_rZ";
export var Title1Normal = "r_rx x_rx x_rY x_r0";
export var Title1Large = "r_ry x_ry x_rY x_r1";
export var BodySmall = "r_rz x_rz x_rY x_sg";
export var BodyNormal = "r_rB x_rB x_rY x_sh";
export var BodyLarge = "r_rC x_rC x_rY x_sj";