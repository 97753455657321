// extracted by mini-css-extract-plugin
export var storyRowWrapperCenter = "t_rN d_bD";
export var storyRowWrapper = "t_hx d_hx d_bK";
export var storyLeftWrapper = "t_rP d_bz d_bP";
export var storyWrapperFull = "t_rQ d_cD";
export var storyWrapperFullLeft = "t_pl d_cD d_bz d_bP d_bD";
export var contentWrapper = "t_mv d_hy";
export var storyLeftWrapperCenter = "t_rR d_hz d_bz d_bD d_bP d_bJ";
export var storyRightWrapperCenter = "t_rS d_hF";
export var storyHeader = "t_rT d_hD d_w d_cs";
export var storyHeaderCenter = "t_hC d_hC d_w d_cs d_dw d_by";
export var storyParagraphCenter = "t_hB d_hB d_by d_dw";
export var storyBtnWrapper = "t_rV d_d2 d_d1 d_w d_bz d_bG";
export var storyBtnWrapperCenter = "t_hJ d_hJ d_d1 d_w d_bz d_bD";
export var imageWrapper = "t_q6 d_fg d_Z";
export var imageWrapperFull = "t_rW d_w d_H d_bf d_Z";
export var SubtitleSmall = "t_qN x_qN x_rY x_r8";
export var SubtitleNormal = "t_qP x_qP x_rY x_r9";
export var SubtitleLarge = "t_qQ x_qQ x_rY x_sb";
export var textLeft = "t_dv";
export var textCenter = "t_dw";
export var textRight = "t_dx";