// extracted by mini-css-extract-plugin
export var alignLeft = "s_rb d_bG";
export var alignCenter = "s_bP d_bD";
export var alignRight = "s_rc d_bH";
export var textAlignLeft = "s_rD";
export var textAlignCenter = "s_rF";
export var textAlignRight = "s_rG";
export var hoursInnerWrapperAlt = "s_rH d_fb d_P d_Z d_bz d_bD d_bP d_dj";
export var hoursInnerWrapperLeft = "s_rJ d_fc d_fb d_P d_Z d_bz d_bD d_bP d_b5 d_dj";
export var hoursText = "s_rK d_dw";
export var titleMargin = "s_rL d_cw";
export var hoursInnerInnerWrapper = "s_rM d_cz";