// extracted by mini-css-extract-plugin
export var campaignContainer = "p_qR d_dW";
export var campaignContainerFull = "p_qS d_dT";
export var wrapper = "p_qT d_w";
export var leftWrapper = "p_qV d_bz d_bN";
export var rightWrapper = "p_qW";
export var subtitle = "p_qX";
export var title = "p_qY";
export var text = "p_qM d_cs";
export var inlineWrapper = "p_qZ d_bC d_bG d_w";
export var btnsWrapper = "p_q0 p_qZ d_bC d_bG d_w d_bJ";
export var priceWrapper = "p_q1 p_qZ d_bC d_bG d_w d_ch d_bP";
export var priceHeading = "p_q2";
export var disclaimerWrapper = "p_q3 d_cD";
export var mediaSlot = "p_q4";
export var listWrapper = "p_q5 d_bC d_bP d_w";
export var btnWrapper = "p_d2";
export var imageWrapper = "p_q6 d_fg d_Z";
export var SmallSmall = "p_q7 x_q7 x_rY x_sk";
export var SmallNormal = "p_q8 x_q8 x_rY x_sl";
export var SmallLarge = "p_q9 x_q9 x_rY x_sh";
export var textLeft = "p_dv";
export var textCenter = "p_dw";
export var textRight = "p_dx";
export var alignLeft = "p_rb";
export var alignCenter = "p_bP";
export var alignRight = "p_rc";